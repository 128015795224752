<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(Review, i) in Reviews"
        :key="i"
        :title="Review.title"
        class="ecommerce-card"
        no-body
      >
        <b-card-body>
          <b-card-title>Review title: {{ Review.title }}</b-card-title>
          <b-card-sub-title>Review type: {{ Review.type }}</b-card-sub-title>
        </b-card-body>
        <b-card-body>
          <b-card-text>Operation title: {{ Review.programs_operationstable.title }}</b-card-text>
          <b-card-sub-text><br>Operation type: {{ Review.programs_operationstable.operations_type }}</b-card-sub-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            v-if="Review.review_status==='disabled'"
            :to="`/startup-portal/programs/review/evaluations/${$route.params.id}/${Review.id}/${$route.params.aid}`"
            class="btn btn-success btn-cart"
          >
            <span>Report</span>
          </b-link>
          <b-link
            v-else
            v-b-modal.selectStartup
            class="btn btn-wishlist btn-danger"
            variant="primary"
          >
            <span>Report</span>
          </b-link>
          <b-link
            :to="`/startup-portal/programs/review/${$route.params.id}/${Review.id}/${$route.params.aid}`"
            class="btn btn-primary btn-cart"
          >
            <span>View / Submit</span>
          </b-link>
        </div>
      </b-card>
    </section>
    <b-modal
      id="selectStartup"
      ok-only
      ok-title="OK"
      size="lg"
      title="Report"
    >
      <p class="my-1">
        No report available
      </p>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardSubTitle, BCardTitle, BLink, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      Reviews: [],
    }
  },
  apollo: {
    Reviews: {
      query() {
        return gql`
      {
       programs_assignmenttable(where: {programs_operationstable: {progress_staage_id: {_eq: ${this.$route.params.prid} }}}) {
          title
          type
          id
          programs_operationstable {
            title
            operations_type
          }
          review_status
          status
        }
      }`
      },
      update: data => data.programs_assignmenttable,

    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
